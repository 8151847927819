import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SecSeven.css";

function SecSeven() {
  const [isMonthlyActive, setIsMonthlyActive] = useState(true);

  const handleToggleChange = () => {
    setIsMonthlyActive(!isMonthlyActive);
  };

  const handleGetStarted = (packageName, monthlyPrice, yearlyPrice) => {
    const pricingType = isMonthlyActive ? "Monthly" : "Yearly";
    const packageAmount = isMonthlyActive ? monthlyPrice : yearlyPrice;

    console.log(
      `Package Name: ${packageName}, Pricing Type: ${pricingType}, Package Amount: ${packageAmount}`
    );
    localStorage.setItem(
      "selectedPackage",
      JSON.stringify({ packageName, pricingType, packageAmount })
    );
  };
  return (
    <>
      {/* <!-- Hero Area============================================ --> */}
      <div className="fix p-5 bannermobi" id="Pricing">
        {/* Toggle Switch */}
        <div className="containertog toggle-switch">
          <p className="ptogle">Yearly</p>
          <input
            type="checkbox"
            id="toggle"
            checked={isMonthlyActive}
            onChange={handleToggleChange}
          />
          <label htmlFor="toggle">Toggle</label>
          <p className="ptogle">Monthly</p>
        </div>
        {/* Tab panes */}
        <div className="tab-content text-center">
          {isMonthlyActive ? (
            <div className="tab-pane active" id="home" role="tabpanel">
              <div className="plans flow-content">
                <div className="plan plan--light">
                  <h2 className="plan-title">basic</h2>
                  <p className="plan-price">
                    $149<span>/month</span>
                  </p>
                  <b>
                    <p className="plan-description">1 Practitioner</p>
                  </b>
                  <p className="plan-description">
                    Designed with the needs of starter clinics or individual
                    practitioners in mind, this package offers optimal solutions
                    tailored to kickstart your journey in the healthcare field
                  </p>
                  <Link
                    to="/DrForm"
                    className="btn"
                    onClick={() =>
                      handleGetStarted(
                        "Basic Package",
                        "$149/month",
                        "$1,499/year"
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--accent">
                  <h2 className="plan-title">Standard</h2>
                  <p className="plan-price">
                    $299<span>/month</span>
                  </p>
                  <p className="plan-description">2-5 Practitioners</p>
                  <p className="plan-description">
                    This package is ideally suited for small to medium-sized
                    practices with 2 to 5 practitioners, providing comprehensive
                    solutions to support the collaborative efforts of your team
                  </p>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="btn"
                    onClick={() =>
                      handleGetStarted("Standard", "$299/month", "$2,999/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--light">
                  <h2 className="plan-title">Premium</h2>
                  <p className="plan-price">
                    $495<span>/month</span>
                  </p>
                  <p className="plan-description">5-25 Practitioners</p>
                  <p className="plan-description">
                    This comprehensive package caters to the needs of medium to
                    large-scale practices, accommodating teams ranging from 5 to
                    25 practitioners to enhance productivity
                  </p>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="btn"
                    onClick={() =>
                      handleGetStarted("Premium", "$495/month", "$4,950/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="tab-pane active" id="profile" role="tabpanel">
              <div className="plans flow-content">
                <div className="plan plan--light">
                  <h2 className="plan-title">basic</h2>
                  <div className="payment-discount-tag">
                    <span className="discount-label">Discount</span>
                    <span className="discount-value">16%</span>
                  </div>
                  <p className="plan-price pt-3">
                    $1,499<span>/year</span>
                  </p>
                  <b>
                    <p className="plan-description">1 Practitioner</p>
                  </b>
                  <p className="plan-description">
                    Designed with the needs of starter clinics or individual
                    practitioners in mind, this package offers optimal solutions
                    tailored to kickstart your journey in the healthcare field
                  </p>
                  <Link
                    to="/DrForm"
                    className="btn"
                    onClick={() =>
                      handleGetStarted(
                        "Basic Package",
                        "$149/month",
                        "$1,499/year"
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--accent">
                  <h2 className="plan-title">Standard</h2>
                  <div className="payment-discount-tag">
                    <span className="discount-label">Discount</span>
                    <span className="discount-value">16%</span>
                  </div>
                  <p className="plan-price pt-3">
                    $2,999<span>/year</span>
                  </p>
                  <p className="plan-description">2-5 Practitioners</p>
                  <p className="plan-description">
                    This package is ideally suited for small to medium-sized
                    practices with 2 to 5 practitioners, providing comprehensive
                    solutions to support the collaborative efforts of your team
                  </p>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="btn"
                    onClick={() =>
                      handleGetStarted("Standard", "$299/month", "$2,999/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--light">
                  <h2 className="plan-title">Premium</h2>
                  <div className="payment-discount-tag">
                    <span className="discount-label">Discount</span>
                    <span className="discount-value">16%</span>
                  </div>
                  <p className="plan-price pt-3">
                    $4,950<span>/year</span>
                  </p>
                  <p className="plan-description">5-25 Practitioners</p>
                  <p className="plan-description">
                    This comprehensive package caters to the needs of medium to
                    large-scale practices, accommodating teams ranging from 5 to
                    25 practitioners to enhance productivity
                  </p>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="btn"
                    onClick={() =>
                      handleGetStarted("Premium", "$495/month", "$4,950/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SecSeven;
