import React, { useEffect, useRef, useState } from "react";
import intlTelInput from "intl-tel-input";
import { Link } from "react-router-dom";
import "intl-tel-input/build/css/intlTelInput.css";
import "./DrForm.css";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import Swal from "sweetalert2";

function DrForm() {
  const phoneInputRef = useRef(null);
  const handleClickPaymentButton = useRef(null);

  const paymentButton = () => {
    handleClickPaymentButton.current.onClick();
  };
  useEffect(() => {
    const phoneInput = phoneInputRef.current;

    if (phoneInput) {
      intlTelInput(phoneInput, {
        initialCountry: "auto",
        separateDialCode: false,
      });
    }
  }, []);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [speciality, setSpeciality] = useState("");

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      fullName,
      email,
      phone,
      password: passwordInput,
      country,
      speciality,
    };
    localStorage.setItem("formData", JSON.stringify(formData));
    console.log(formData);
  };

  const onSignup = async () => {
    // Show loader while waiting for response
    const loader = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = {
      Full_Name: fullName,
      Email: email,
      Mobile_Number: phone,
      Password: passwordInput,
      DoctorSpecility: speciality,
      country: country,
      package_name: selectedPlan.packageName,
      duration: selectedPlan.pricingType,
    };

    try {
      const response = await axios.post(
        "https://portal.virtualtriage.ca/doctor_signup_validator",
        formData
      );

      const data = response.data;
      console.log(data);
      if (response.data.status) {
        paymentButton();
      }
      // Close loader upon successful API call
      loader.close();

      // Show success message
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Your account request send successfully`,
      });
    } catch (error) {
      console.error("Error:", error);
      // Close loader if it's still open
      if (loader.isOpen) {
        loader.close();
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Response Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        console.error("Response Headers:", error.response.headers);
        // Show error using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Opps Error",
          text: error.response.data.message, // Adjust to your API response structure
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request Error:", error.request);
        // Show error using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "No response received from server. Please try again later.",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
        // Show error using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Unknown Error",
          text: "An error occurred. Please try again later.",
        });
      }
    }
  };

  const onToken = async (token) => {
    // Show loader while waiting for response
    const loader = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = {
      Full_Name: fullName,
      Email: email,
      Mobile_Number: phone,
      Password: passwordInput,
      DoctorSpecility: speciality,
      country: country,
      package_name: selectedPlan.packageName,
      stripe_token: token.id,
      duration: selectedPlan.pricingType,
    };

    try {
      const response = await axios.post(
        "https://portal.virtualtriage.ca/doctor_signup_with_package",
        formData
      );

      const data = response.data;
      console.log(data);

      // Close loader upon successful API call
      loader.close();

      // Show success message
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your account Created successfully",
      }).then(() => {
        // Redirect to login page
        window.location.href = "https://portal.virtualtriage.ca/login"; // Replace with your actual login page URL
      });
    } catch (error) {
      console.error("Error:", error);
      // Close loader if it's still open
      if (loader.isOpen) {
        loader.close();
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Response Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        console.error("Response Headers:", error.response.headers);
        // Show error using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Opps Error",
          text: error.response.data.message, // Adjust to your API response structure
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request Error:", error.request);
        // Show error using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "No response received from server. Please try again later.",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
        // Show error using SweetAlert
        Swal.fire({
          icon: "error",
          title: "Unknown Error",
          text: "An error occurred. Please try again later.",
        });
      }
    }
  };

  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    const storedPlan = JSON.parse(localStorage.getItem("selectedPackage"));
    if (storedPlan) {
      setSelectedPlan(storedPlan);
    }
  }, []);

  return (
    <>
      <div
        className="container-float-start mainSignbnner"
        style={{ backgroundColor: "#51cada", height: "123vh" }}
      >
        <div style={{ scale: "0.8", marginLeft: "-18%" }}>
          <img
            className="Logo Virtual Triage"
            src="/assest/images/Login/drgif.gif"
            alt="Digital Triage"
          />
        </div>

        <div className="DrBox" id="drbox">
          {" "}
          <Link to={"/"}>
            <img
              className="user"
              alt="Virtual Health"
              id="users"
              src="/assest/images/HomeImages/logo.png"
              height="100px"
              width="100px"
            />
          </Link>
          <h3 style={{ fontSize: "17px", fontFamily: "sans-serif" }}>
            Practitioner SignUp
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="inputBox">
              <input
                id="uname"
                type="text"
                name="Username"
                placeholder="Enter Your Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                style={{ background: "white", color: "black" }}
              />{" "}
              <input
                id="email"
                type="email"
                name="Email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ background: "white", color: "black" }}
              />{" "}
              <fieldset>
                <input
                  type="tel"
                  ref={phoneInputRef}
                  placeholder="Enter Your Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{
                    background: "white",
                    color: "black",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                />
              </fieldset>
              <div className="input-group">
                <input
                  type={passwordType}
                  onChange={handlePasswordChange}
                  value={passwordInput}
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  style={{ background: "white", color: "black" }}
                />
                <div className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-outline-primary eyebtn"
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-solid fa-eye"></i>
                    )}
                  </button>
                </div>
              </div>
              <fieldset className="pt-3">
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option disabled selected>
                    Select Country
                  </option>
                  <option value="Canada">Canada</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="United States">United States</option>
                  <option value="United Kingdom">United Kingdom</option>
                </select>
              </fieldset>
              <fieldset className="pt-2">
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  value={speciality}
                  onChange={(e) => setSpeciality(e.target.value)}
                >
                  <option disabled selected>
                    Select Speciality
                  </option>
                  <option value="Aesthetic">Aesthetic</option>
                  <option value="Audiologist">Audiologist</option>
                  <option value="Cannabis">Cannabis</option>
                  <option value="Chiropractic">Chiropractic</option>
                  <option value="Dental">Dental</option>
                  <option value="Dermatology">Dermatology</option>
                  <option value="Eye Care">Eye Care</option>
                  <option value="Fertility">Fertility</option>
                  <option value="General Physician">General Physician</option>
                  <option value="Hair Treatments">Hair Treatments</option>
                  <option value="Medical Therapy">Medical Therapy</option>
                  <option value="Naturopathic">Naturopathic</option>
                  <option value="Neurologist">Neurologist</option>
                  <option value="Nutrition">Nutrition</option>
                  <option value="Optometry">Optometry</option>
                  <option value="Orthotics">Orthotics</option>
                  <option value="Physiotherapy">Physiotherapy</option>
                  <option value="Plastic Surgery">Plastic Surgery</option>
                  <option value="Podiatry">Podiatry</option>
                  <option value="Psychology">Psychology</option>
                  <option value="Radiologist">Radiologist</option>
                  <option value="Sleep">Sleep</option>
                  <option value="Sports Medicine">Sports Medicine</option>
                  <option value="Veterinary">Veterinary</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Women's Health">Women's Health</option>
                </select>
              </fieldset>
            </div>

            <div className="text-center">
              <button
                type="button"
                onClick={onSignup}
                style={{
                  outline: "none",
                  height: "40px",
                  fontSize: "16px",
                  background: "#51cada",
                  color: "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                  border: "1px solid white",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#3ca3ba")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#51cada")}
              >
                Continue
              </button>
              <StripeCheckout
                ref={handleClickPaymentButton}
                type="submit"
                value="Continue"
                className="d-none"
                token={onToken}
                stripeKey="pk_test_51PhrUYK9RQxOD12kdbkwCXMYADxr4HNfvJ3AG3qM1m5Tm09qH0LPBhKgSpVyBVy9GiI6n7n9EPLd2hquwcULtQKI00rCw3WC2a"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DrForm;
