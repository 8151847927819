import React, { useEffect, useRef, useState } from "react";
import intlTelInput from "intl-tel-input";
import { Link } from "react-router-dom";
import "intl-tel-input/build/css/intlTelInput.css";
import "./DrForm.css";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import Swal from "sweetalert2";

function ClinicForm() {
  const phoneInputRef = useRef(null);
  const handleClickPaymentButton = useRef(null);

  const paymentButton = () => {
    handleClickPaymentButton.current.onClick();
  };

  useEffect(() => {
    const phoneInput = phoneInputRef.current;

    if (phoneInput) {
      intlTelInput(phoneInput, {
        initialCountry: "auto",
        separateDialCode: false,
      });
    }
  }, []);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [packagename, setPackagename] = useState("");
  const [duration, setDuration] = useState("");

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const togglePassword = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      clinic_name: fullName,
      clinic_username: userName,
      clinic_address: clinicAddress,
      email: email,
      mobile_number: phone,
      password: passwordInput,
      package_name: packagename + " package",
      duration: duration,
    };

    try {
      const response = await axios.post(
        "https://portal.virtualtriage.ca/clinic_signup_validator",
        formData
      );

      console.log(response.data);

      localStorage.setItem("formData", JSON.stringify(formData));

      setFullName("");
      setUserName("");
      setClinicAddress("");
      setEmail("");
      setPhone("");
      setPasswordInput("");
      setPackagename("");
      setDuration("");

      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit form. Please try again later.");
    }
  };

  const onSignup = async () => {
    const loader = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = {
      clinic_name: fullName,
      clinic_username: userName,
      clinic_address: clinicAddress,
      email: email,
      mobile_number: phone,
      password: passwordInput,
      package_name: selectedPlan.packageName + " package",
      duration: selectedPlan.pricingType,
    };

    try {
      const response = await axios.post(
        "https://portal.virtualtriage.ca/clinic_signup_validator",
        formData
      );

      const data = response.data;
      console.log(data);
      if (response.data.status) {
        paymentButton();
      }

      loader.close();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your clinic account request has been sent successfully",
      });
    } catch (error) {
      console.error("Error:", error);

      if (loader.isOpen) {
        loader.close();
      }

      if (error.response) {
        console.error("Response Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        Swal.fire({
          icon: "error",
          title: "Oops! Error",
          text: error.response.data.message,
        });
      } else if (error.request) {
        console.error("Request Error:", error.request);
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "No response received from server. Please try again later.",
        });
      } else {
        console.error("Error:", error.message);
        Swal.fire({
          icon: "error",
          title: "Unknown Error",
          text: "An error occurred. Please try again later.",
        });
      }
    }
  };

  const onToken = async (token) => {
    const loader = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = {
      clinic_name: fullName,
      clinic_username: userName,
      clinic_address: clinicAddress,
      email: email,
      mobile_number: phone,
      password: passwordInput,
      package_name: selectedPlan.packageName + " package",
      stripe_token: token.id,
      duration: selectedPlan.pricingType,
    };

    try {
      const response = await axios.post(
        "https://portal.virtualtriage.ca/clinic_signup_with_package",
        formData
      );

      const data = response.data;
      console.log(data);

      loader.close();

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your account Created successfully",
      }).then(() => {
        window.location.href = "https://portal.virtualtriage.ca/client_Login"; // Replace with your actual login page URL
      });
    } catch (error) {
      console.error("Error:", error);

      if (loader.isOpen) {
        loader.close();
      }

      if (error.response) {
        console.error("Response Data:", error.response.data);
        console.error("Response Status:", error.response.status);
        Swal.fire({
          icon: "error",
          title: "Opps Error",
          text: error.response.data.message,
        });
      } else if (error.request) {
        console.error("Request Error:", error.request);
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "No response received from server. Please try again later.",
        });
      } else {
        console.error("Error:", error.message);
        Swal.fire({
          icon: "error",
          title: "Unknown Error",
          text: "An error occurred. Please try again later.",
        });
      }
    }
  };

  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    const storedPlan = JSON.parse(localStorage.getItem("selectedPackage"));
    if (storedPlan) {
      setSelectedPlan(storedPlan);
    }
  }, []);

  return (
    <>
      <div
        className="container-float-start mainSignbnner"
        style={{ backgroundColor: "#51cada", height: "123vh" }}
      >
        <div style={{ scale: "0.8", marginLeft: "-18%" }}>
          <img
            className="Logo Virtual Triage"
            src="/assest/images/Login/drgif.gif"
            alt="Digital Triage"
          />
        </div>

        <div className="DrBox" id="drbox">
          {" "}
          <Link to={"/"}>
            <img
              className="user"
              alt="Virtual Health"
              id="users"
              src="/assest/images/HomeImages/logo.png"
              height="100px"
              width="100px"
            />
          </Link>
          <h3 style={{ fontSize: "17px", fontFamily: "sans-serif" }}>
            Clinic info
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="inputBox">
              <input
                id="clinicname"
                type="text"
                name="clinicname"
                placeholder="Clinic Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                style={{ background: "white", color: "black" }}
              />
              <input
                id="clinicusername"
                type="text"
                name="clinicusername"
                placeholder="Clinic User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{ background: "white", color: "black" }}
              />
              <input
                id="clinicaddress"
                type="text"
                name="clinicaddress"
                placeholder="Clinic Address"
                value={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
                style={{ background: "white", color: "black" }}
              />
              <input
                id="email"
                type="email"
                name="Email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ background: "white", color: "black" }}
              />
              <fieldset>
                <input
                  type="tel"
                  ref={phoneInputRef}
                  placeholder="Clinic Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{
                    background: "white",
                    color: "black",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                />
              </fieldset>
              <div className="input-group">
                <input
                  type={passwordType}
                  onChange={handlePasswordChange}
                  value={passwordInput}
                  name="password"
                  className="form-control"
                  placeholder="Clinic Password"
                  style={{ background: "white", color: "black" }}
                />
                <div className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-outline-primary eyebtn"
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-solid fa-eye"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="text-center">
              <button
                type="button"
                onClick={onSignup}
                style={{
                  outline: "none",
                  height: "40px",
                  fontSize: "16px",
                  background: "#51cada",
                  color: "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                  border: "1px solid white",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#3ca3ba")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#51cada")}
              >
                SignUp
              </button>
              <StripeCheckout
                ref={handleClickPaymentButton}
                type="submit"
                value="Continue"
                className="d-none"
                token={onToken}
                stripeKey="pk_test_51PhrUYK9RQxOD12kdbkwCXMYADxr4HNfvJ3AG3qM1m5Tm09qH0LPBhKgSpVyBVy9GiI6n7n9EPLd2hquwcULtQKI00rCw3WC2a"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ClinicForm;
